import React from "react";
import SEO from "../../components/seo";
import Layout from "../../components/Layout/Layout";
import Hero from "../../components/Hero/Hero";
import Img from "gatsby-image";
import styled from "styled-components";

const PlanetWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: -100px;
  img {
    width: 340px;
  }
  margin-bottom: 40px;
  @media only screen and (max-width: 1100px) {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  @media only screen and (max-width: 768px) {
    flex-direction: column !important;
    margin-top: 0px;
    margin-bottom: 20px;
  }
`;

const PlanetImage = styled(Img)`
  width: 340px;
  @media only screen and (max-width: 768px) {
    transform: none !important;
  }
  @media only screen and (max-width: 500px) {
    width: 200px;
  }
`;

const PlanetText = styled.div`
  /* display: flex; */
  flex: 1;
  margin-top: -45px;
  margin-left: -13px;
  max-width: 500px;
  hr {
    width: 50px;
  }
  h2 {
    font-size: 56px;
    padding-bottom: 4px;
  }
  p {
    padding-bottom: 0;
    font-size: 18px;
    clear: both;
  }
  @media only screen and (max-width: 768px) {
    text-align: center !important;
    max-width: 380px;
    margin-top: -30px;
    margin-left: 0px;
    hr {
      float: none !important;
      margin: 2px auto 15px;
    }
    h2 {
      font-size: 42px;
    }
  }
  @media only screen and (max-width: 500px) {
    margin-top: -10px;
  }
`;

const Planets = styled.div`
  max-width: 1150px;
  margin: 0 auto;
  padding-bottom: 150px;
  padding-top: 60px;
  @media only screen and (max-width: 768px) {
    padding-top: 0px;
    padding-bottom: 100px;
  }
`;

const BigCircle = styled.div`
  position: absolute;
  height: 2000px;
  width: 2000px;
  border: 2px solid white;
  border-radius: 100%;
  transform: translate(-1300px, -100px);
  opacity: 0.3;
`;

const BigCircle2 = styled.div`
  position: absolute;
  height: 1500px;
  width: 1500px;
  border: 2px solid white;
  border-radius: 100%;
  transform: translate(-1055px, 147px);
  opacity: 0.3;
`;

const BigCircle3 = styled.div`
  position: absolute;
  height: 1000px;
  width: 1000px;
  border: 2px solid white;
  border-radius: 100%;
  transform: translate(-800px, 403px);
  opacity: 0.3;
`;

const BigCircle4 = styled.div`
  position: absolute;
  height: 500px;
  width: 500px;
  border: 2px solid white;
  border-radius: 100%;
  transform: translate(-554px, 650px);
  opacity: 0.3;
`;

const BigCircle5 = styled.div`
  position: absolute;
  height: 2000px;
  width: 2000px;
  border: 2px solid white;
  border-radius: 100%;
  transform: translate(-1300px, -100px);
  opacity: 0.3;
`;

const BigCircle6 = styled.div`
  position: absolute;
  height: 1500px;
  width: 1500px;
  border: 2px solid white;
  border-radius: 100%;
  transform: translate(-1055px, 147px);
  opacity: 0.3;
`;

const BigCircle7 = styled.div`
  position: absolute;
  height: 1000px;
  width: 1000px;
  border: 2px solid white;
  border-radius: 100%;
  transform: translate(-800px, 403px);
  opacity: 0.3;
`;

const BigCircle8 = styled.div`
  position: absolute;
  height: 500px;
  width: 500px;
  border: 2px solid white;
  border-radius: 100%;
  transform: translate(-554px, 650px);
  opacity: 0.3;
`;

const FirstCircle = styled.div`
  transform: translate(0, 60px);
  @media only screen and (max-width: 1100px) {
    display: none;
  }
`;

const SecondCircle = styled.div`
  transform: translate(1936px, 1930px);
  @media only screen and (max-width: 1100px) {
    display: none;
  }
`;

const DevTools = styled.div`
  display: flex;
  max-width: 1100px;
  margin: 0 auto 100px;
  @media only screen and (max-width: 768px) {
    display: block;
  }
`;
const DevText = styled.div`
  width: 37%;
  padding-right: 40px;
  @media only screen and (max-width: 768px) {
    width: 100%;
    text-align: center;
    padding-right: 0px;
    max-width: 600px;
    margin: 0 auto;
  }
`;
const DevIcons = styled.div`
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  margin-top: -22px;
`;
const SVGImage = styled.img`
  max-width: 66px;
`;
const DevIconWrapper = styled.div`
  width: 25%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 30px;
  @media only screen and (max-width: 768px) {
    width: 33.33333333333333%;
  }
`;

const planetsData = [
  {
    title: "INNOVATION",
    text:
      "We believe innovation is a mindset. That's why we work with you to identify and deliver your ambition. We'll also help to distil those values into your business ethos.",
    imageKey: "innovation",
    left: "0%",
  },
  {
    title: "DISCOVERY",
    text:
      "Creating the foundation of any project is important. We know that. But defining the right goals, scope and audience is the real trick. It just so happens we're pretty good at that.",
    imageKey: "discovery",
    left: "23%",
    imageTranslate: "0, -35px",
  },

  {
    title: "PROOF OF CONCEPT",
    text:
      "Isn't it fun when you realise you're the first to put a foot print down somewhere? Best to take baby steps and build out that technical proof of concept before we go all in.",
    imageKey: "proofOfConcept",
    left: "36%",
  },
  {
    title: "PROTOTYPE",
    text:
      "Everyone loves a prototype. Whether to justify a business case, bring an idea to life or to help quickly test the next big thing. There's nothing like seeing the first glimpse of what the future looks like.",
    imageKey: "prototype",
    left: "36%",
  },
  {
    title: "USER TESTING",
    text:
      "No one has all the answers. We certainly don't. So, we help close the knowledge gap through our lean user-testing processes. Your users want perfection, so let's give it to them.",
    imageKey: "userTesting",
    left: "29%",
  },
  {
    title: "PILOT",
    text:
      "Not all good ideas are obvious immediately and sometimes things just don't work out. We help brands launch their pilot rapidly to test, learn and refine. The minimum outputs are insights and learnings, but we could be on to something truly special.",
    imageKey: "pilot",
    left: "10%",
  },
  {
    title: "PRODUCT DESIGN",
    text:
      "Consistency and clarity go a long way with users. Using our expertise, we can create a design language and system that users will recognize anywhere. Simple, stunning and significant.",
    imageKey: "design",
    flip: true,
    // top: "135px",
    left: "0px",
  },
  {
    title: "PRODUCT DEVELOPMENT",
    text:
      "It's time to actually build this thing! We pride ourselves in creating products that are fast, reliable and secure, using the most modern tools and web technologies available.",
    imageKey: "development",
    left: "290px",
    flip: true,
    imageTranslate: "0, -30px",
  },

  {
    title: "TECH STRATEGY",
    text:
      "Need some help working out what technologies to use? Maybe you have a design but aren't sure how best to build it? We're here to help.",
    imageKey: "technology",
    left: "410px",
    flip: true,
    // imageTranslate: "0, -30px",
  },

  {
    title: "PRODUCT VALUE PROPOSITION",
    text:
      "Giving meaning to your product is important. So is knowing where it's going to go and what it needs to do from the outset - even if it changes along the way. Let us be your guide.",
    imageKey: "product",
    left: "430px",
    flip: true,
  },

  {
    title: "PRODUCT STRATEGY",
    text:
      "A strategy for your product is one thing. But do you fully know the value it creates and how that fits into your brand eco-system? We'd love to show you.",
    imageKey: "strategy",
    left: "350px",
    flip: true,
  },

  {
    title: "PROGRAMME",
    text:
      "Successful products aren't just one offs. They need ongoing attention and love, which is where our programmes come in. We'll manage your on-going product development lifecycle for you, allowing you to focus on what's important.",
    imageKey: "programme",
    left: "170px",
    flip: true,
  },
];

const ServicesPage = ({ data }) => {
  console.log(data);
  return (
    <Layout>
      <Hero
        smaller
        subtitle="We can support all or part of your product lifecycle. All you need to do is tell us where we fit in your universe."
      >
        AIM HIGHER.
        <br />
        LET US SHOW YOU HOW
      </Hero>
      <FirstCircle>
        <BigCircle></BigCircle>
        <BigCircle2></BigCircle2>
        <BigCircle3></BigCircle3>
        <BigCircle4></BigCircle4>
      </FirstCircle>
      <SecondCircle>
        <BigCircle5></BigCircle5>
        <BigCircle6></BigCircle6>
        <BigCircle7></BigCircle7>
        <BigCircle8></BigCircle8>
      </SecondCircle>

      <Planets>
        {planetsData.map((p) => (
          <PlanetWrapper
            style={{
              [p.flip ? "paddingRight" : "paddingLeft"]: p.left,
              paddingTop: p.top,
              flexDirection: p.flip ? "row-reverse" : "initial",
            }}
          >
            <PlanetImage
              fluid={data[p.imageKey].childImageSharp.fluid}
              style={{ transform: `translate(${p.imageTranslate})` }}
            />
            <PlanetText style={{ textAlign: p.flip ? "right" : "left" }}>
              <h2>{p.title}</h2>
              <hr style={{ float: p.flip ? "right" : "left" }} />
              <p>{p.text}</p>
            </PlanetText>
          </PlanetWrapper>
        ))}
      </Planets>

      <DevTools>
        <DevText>
          <h2>MAKING IT HAPPEN</h2>
          <p>
            We treat every problem as unique and seek out the best tools for
            each particular job. These are just some of our favourite
            development tools that we've been using recently.
          </p>
        </DevText>
        <DevIcons>
          <DevIconWrapper title="React">
            <SVGImage src={require("../../images/tools/react.svg")} />
          </DevIconWrapper>
          <DevIconWrapper title="Redux">
            <SVGImage src={require("../../images/tools/redux.svg")} />
          </DevIconWrapper>
          <DevIconWrapper title="Graphql">
            <SVGImage src={require("../../images/tools/graphql.svg")} />
          </DevIconWrapper>
          <DevIconWrapper title="Node">
            <SVGImage src={require("../../images/tools/node.svg")} />
          </DevIconWrapper>
          <DevIconWrapper title="Typescript">
            <SVGImage src={require("../../images/tools/typescript.svg")} />
          </DevIconWrapper>
          <DevIconWrapper title="Jest">
            <SVGImage src={require("../../images/tools/jest.svg")} />
          </DevIconWrapper>
          <DevIconWrapper title="Gatsby">
            <SVGImage src={require("../../images/tools/gatsby.svg")} />
          </DevIconWrapper>
          <DevIconWrapper title="Docker">
            <SVGImage src={require("../../images/tools/docker.svg")} />
          </DevIconWrapper>
          <DevIconWrapper title="Zeplin">
            <SVGImage src={require("../../images/tools/zeplin.svg")} />
          </DevIconWrapper>
          <DevIconWrapper title="Github">
            <SVGImage src={require("../../images/tools/github.svg")} />
          </DevIconWrapper>
          <DevIconWrapper title="Amazon Web Services">
            <SVGImage src={require("../../images/tools/aws.svg")} />
          </DevIconWrapper>
          <DevIconWrapper title="Jira">
            <SVGImage
              src={require("../../images/tools/jira.svg")}
              style={{ transform: "scale(1.1)" }}
            />
          </DevIconWrapper>
        </DevIcons>
      </DevTools>
    </Layout>
  );
};

export default ServicesPage;

export const query = graphql`
  query {
    design: file(relativePath: { eq: "planets/design.png" }) {
      childImageSharp {
        fluid(maxWidth: 340, quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    development: file(relativePath: { eq: "planets/development.png" }) {
      childImageSharp {
        fluid(maxWidth: 340, quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    technology: file(relativePath: { eq: "planets/technology.png" }) {
      childImageSharp {
        fluid(maxWidth: 340, quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    discovery: file(relativePath: { eq: "planets/discovery.png" }) {
      childImageSharp {
        fluid(maxWidth: 340, quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    innovation: file(relativePath: { eq: "planets/innovation.png" }) {
      childImageSharp {
        fluid(maxWidth: 340, quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    pilot: file(relativePath: { eq: "planets/pilot.png" }) {
      childImageSharp {
        fluid(maxWidth: 340, quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    product: file(relativePath: { eq: "planets/product.png" }) {
      childImageSharp {
        fluid(maxWidth: 340, quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    programme: file(relativePath: { eq: "planets/programme.png" }) {
      childImageSharp {
        fluid(maxWidth: 340, quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    proofOfConcept: file(relativePath: { eq: "planets/proof-of-concept.png" }) {
      childImageSharp {
        fluid(maxWidth: 340, quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    prototype: file(relativePath: { eq: "planets/prototype.png" }) {
      childImageSharp {
        fluid(maxWidth: 340, quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    strategy: file(relativePath: { eq: "planets/strategy.png" }) {
      childImageSharp {
        fluid(maxWidth: 340, quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    userTesting: file(relativePath: { eq: "planets/user-testing.png" }) {
      childImageSharp {
        fluid(maxWidth: 340, quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`;
